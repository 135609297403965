<script>
import { mapMutations, mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
import { validCPF } from '@commons/validations';
import http from '@commons/http';
import Recaptcha from '@components/Recaptcha';
import FaleConosco from '@components/login/FaleConosco';

export default {
	name: 'LoginRecoverMethod',
	components: {
		Recaptcha,
		FaleConosco,
	},
	data() {
		return {
			notification: false,
			form: {
				cpf: ""
			},
			loading: false,
		}
	},
	validations: {
		form: {
			cpf: {
				required,
				validCPF
			}
		}
	},
	computed: {
		...mapGetters('senha', [
			'getEmailMasked',
			'getCpf',
			'getMetodos',
			'hasMultipleMethods',

		]),
	},
	mounted() {
		if (!this.getCpf || this.getCpf == 'null') {
			this.goto();
		}
	},
	methods: {
		...mapMutations('senha', [
			'setEmailMasked',
			'setCpf',
			'setExpTime',
			'setReseId',
		]),
		goto() {
			this.$router.push({ name: 'login.home' });
		},
		email_code() {
			this.$router.push({ name: 'login.email_code' });
		},
		async sendEmailCode() {
			if (this.notification) this.notification.close();

			const body = new FormData();
			body.append('cpf', this.getCpf);
			try {
				const captcha = await this.$refs.grecaptcha.generate();

				body.append('captcha', captcha);
			} catch (error) {

				console.log(error);
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Recaptcha inválido!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
				return;
			}

			this.loading = true;
			http.post('/v1/auth/senha/metodos/email', body).then(({ data, status }) => {
				this.loading = false;
				if (status >= 400) {
					console.log(data.message);
				}
				this.setExpTime(parseInt(data.exp_time, 10));
				this.setReseId(parseInt(data.rese_id, 10));

				this.email_code();
			}, () => {
				this.loading = false;
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: `Ocorreu um erro!`,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		}
	}
}
</script>


<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 v-if="!hasMultipleMethods" class="title is-1">
				Por favor, confirme o seu email abaixo:
			</h2>
			<h2 v-else class="title is-1">
				Por favor, escolha como você prefere realizar a redefinição da sua senha:
			</h2>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<div v-if="!hasMultipleMethods">
					<div class="columns is-multiline is-centered">
						<div class="box column has-text-centered box-ebradi">
							<p class="title mb-3 is-4">
								E-mail
							</p>
							<p>{{ getEmailMasked }}</p>
						</div>
						<form novalidate class="form column is-12 has-text-centered mt-4" @submit.prevent="sendEmailCode">

							<button class="button is-primary is-rounded">
								Enviar código para redefinir senha
							</button>


						</form>
						<div class="column is-12 has-text-centered mt-0">
							<button class="button is-primary is-rounded is-outlined" @click="goto">
								Voltar para o login
							</button>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="columns is-multiline">
						<div class="box">
							{{ getEmailMasked }}
						</div>
						<form novalidate class="form column is-12 has-text-centered mt-4">
							<button class="button is-primary is-rounded">
								Enviar código para redefinir senha
							</button>
						</form>
						<div class="column is-12 has-text-centered mt-0">
							<button class="button is-primary is-rounded is-outlined" @click="goto">
								Voltar para o login
							</button>
						</div>

					</div>
				</div>
				<div class="mt-6">
					<h4 class="has-text-centered">Não reconhece seu e-mail ou não possui mais acesso a ele?</h4>
					<FaleConosco></FaleConosco>
				</div>
			</div>


		</div>

		<b-loading v-model="loading" :is-full-page="true" :can-cancel="false" />
		<Recaptcha ref="grecaptcha"></Recaptcha>
	</div>
</template>

<style scoped>
.notification {
	background-color: transparent;
}
</style>
